.section-container {
  max-height: calc(40vw, 40%);
}

.hero-section {
  padding-top: 32px;
  padding-left: 30px;
  padding-right: 15px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  background: linear-gradient(to right, #ecf2ff, #fbfcff);
}
/* Text section */
.text-section {
  width: 106%;
  padding: 0 32px;
}
.text-headline {
  margin-bottom: 12px;
  color: rgb(77, 75, 75);
  font-family: "Rubik", sans-serif;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.7px;
}
.text-title {
  width: 500px;
  color: black;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: bold;
}
.text-descritpion {
  width: 475px;
  margin: 32px 0;
  color: rgb(77, 75, 75);
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  letter-spacing: 0.7px;
  line-height: 1.6rem;
}
.text-appointment-btn {
  padding: 14px 20px;
  color: white;
  border: 1px solid transparent;
  border-radius: 28px;
  outline: transparent;
  background-color: #f99700;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.8px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.text-appointment-btn:hover {
  color: #1a8efd;
  background-color: transparent;
  border: 1px solid #1a8efd;
}
.text-stats {
  margin-top: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
}
.text-stats-container {
  text-align: center;
}
.text-stats-container > p {
  color: rgb(77, 75, 75);
  font-family: "Poppins", sans-serif;
  font-size: 22px;
  letter-spacing: 0.7px;
}
.text-stats-container p:first-child {
  margin-bottom: 8px;
  color: #1a8efd;
  font-family: "Rubik", sans-serif;
  font-size: 32px;
  font-weight: bold;
}
/* Image section */
.hero-image-section {
  width: 60%;
  max-width: 100%;
  text-align: center;
}
.hero-image1 {
  width: 90%;
  height: auto;
}
/* Scroll Btn */
.scroll-up {
  width: 45px;
  height: 45px;
  display: none;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: white;
  border: 3px solid white;
  border-radius: 50%;
  background-color: #1a8efd;
  position: fixed;
  bottom: 100px;
  right: 50px;
  font-size: 24px;
  z-index: 20;
  cursor: pointer;
}
.show-scroll {
  display: flex;
}
/* Responsive */
@media screen and (max-width: 900px) {
  .hero-image-section {
    display: none;
  }
  .text-section {
    width: 100%;
    padding: 0;
  }
  .text-title,
  .text-descritpion {
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
.text-headline {
        font-size: 16px;
    }
    .text-title {
        font-size: 18px;
    }
    .text-descritpion {
        font-size: 16px;
        display: none;
    }
    .text-appointment-btn {
        font-size: 12px;
        margin-top: 6px;
    }
    .text-stats {
        gap: 18px;
        margin-top: 15px;
    }
    .text-stats-container > p {
        font-size: 16px;
    }
    .text-stats-container p:first-child {
        font-size: 22px;
    }
    .scroll-up {
        bottom: 32px;
        right: 32px;
    }
    .hero-section {
        padding-left: 15px;
    }
}
