
.container {
  display: flex;
  height: 100vh;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; /* Adjust as needed */
}

.right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  width: 80%;
  max-width: 400px;
  padding: 20px;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px; /* Added margin for spacing */
}

.login-form input {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #cccccc99;
}

.login-form button {
  width: 50%;
  margin-top: 10px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #038836;
  color: #fff;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #0056b3;
}

.forgot-password {display: flex;
  padding-top: 10px;
  align-self: start;
  /* text-align: center; */
}

.forgot-password a {
  color: #007bff;
  text-decoration: none;
}

.right img {
  max-width: 100%;
  height: auto;
}

.password-input {
  position: relative;
}

.password-input input {
  padding-right: 40px; /* Make space for the icon */
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .left {
    order: 2;
  }

  .right {
    order: 1;
  }

  .login-form {
    width: 100%;
    max-width: none;
    margin-bottom: 500px;
  }
}