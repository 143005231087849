.navbar-section {
  padding: 0 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #90adc6;
}

.navbar-logo {
  height: 85px;
  margin-right: 10px;
}
.navbar-sign {
  color: #54de54;
  font-family: Cambria, sans-serif;
  font-size: 40px;
  font-weight: bold;
}
.navbar-items {
  margin-top: 15px;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
  font-family: "Rubik", sans-serif;
}
.navbar-items > li {
  list-style-type: none;
}
.navbar-links {
  text-decoration: none;
  color: black;
  font-size: 18px;
  letter-spacing: 0.8px;
}
.navbar-links:hover {
  color: #f99700;
}
.navbar-btn {
  padding: 14px 20px;
  color: white;
  border: 1px solid transparent;
  border-radius: 28px;
  outline: transparent;
  background-color: #4fce5d;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.8px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.navbar-btn:hover {
  color: #4fce5d;
  background-color: white;
  border: 1px solid #015f32;
}
/* Hamburger Icon */
.mobile-nav {
  display: none;
}
.hamb-icon {
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.hamb-icon:hover {
  color: #f99700;
}
/* Mobile Navbar */
.mobile-navbar {
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: rgba(255, 255, 255, 0.453);
  backdrop-filter: blur(10px);
  z-index: 20;
  justify-content: center;
  align-items: center;
  transition: left 0.5s ease-in-out;
}
.mobile-navbar-close {
  position: absolute;
  top: 28px;
  right: 28px;
}
.mobile-navbar-close .hamb-icon:hover {
  color: rgb(255, 22, 22);
}
.open-nav {
  left: 0;
}
.mobile-navbar-links {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  gap: 24px;
  text-align: center;
}
.mobile-navbar-links li a {
  text-decoration: none;
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  letter-spacing: 0.8px;
  transition: color 0.3s ease-in-out;
}
.mobile-navbar-links li a:hover {
  color: #f99700;
}
/* Responsive */
@media screen and (max-width: 900px) {
  .navbar-btn,
  .navbar-items {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
}
